@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../node_modules/reveal.js/css/reveal.css";
@import "../node_modules/reveal.js/css/theme/solarized.css";

.reveal blockquote {
  box-shadow: none;
  border-left: 2px solid rgba(0,0,0,0.2);
}
